import { Link } from "gatsby";
import React from "react";
import AnimationWrapper from "../components/AnimationWrapper";
import Menu from "../components/Menu";
import SEO from "../components/SEO";
import "../styles/main.scss";

export default function index() {
  return (
    <AnimationWrapper>
      <SEO />
      <div>
        <Menu />
        <main className="main-container">
          <div className="main-inner">
            <div className="glass-container">
              <div className="glass">
                <h1 id="h1-animate">Kitárul a Világ!</h1>

                <div className="quote-main">
                  <p>
                    <span>„</span>És érezzék egy kézfogásról rólad, hogy jót
                    akarsz, és te is tiszta jó vagy, s egy tekintetük elhitesse
                    véled: szép dolgokért élsz - és érdemes élned.
                    <span>”</span>
                  </p>
                  <p>
                    <em>- Váci Mihály</em>
                  </p>
                </div>
              </div>

              <Link to="/about">
                <button className="btn">Ismerj meg minket!</button>
              </Link>
            </div>
          </div>
        </main>
      </div>
    </AnimationWrapper>
  );
}
